const requestApi = require("@/lib/request");
// 配置接口
module.exports = {
    //Banner列表
    bannerList() {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/banner-list').then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //Banner操作
    bannerForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/banner-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //Banner排序
    bannerOrder(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/banner-order', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //删除Banner
    bannerDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/banner-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //Banner列表
    foodList() {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/food-list').then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //Banner操作
    foodForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/food-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //Banner排序
    foodOrder(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/food-order', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //删除Banner
    foodDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/setting/food-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    }
}