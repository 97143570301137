const requestApi = require("@/lib/request");
//资源接口
module.exports = {
    //列表
    resourceList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/resource/resource-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //列表
    winList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/resource/win-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //删除资源
    resourceDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/resource/resource-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
}