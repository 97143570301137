<template>
  <div>
    <el-dialog title="选择资源" :visible.sync="visible" @close="resWinClose" top="10px" width="840px">
      <div class="page_search">
        <el-form ref="searchForm" :model="searchForm">
          <el-row type="flex">
            <el-col class="search_col">
              <el-input size="small" style="width: 180px" type="text" v-model="searchForm.key" placeholder="名称" />
            </el-col>
            <el-col class="search_col">
              <el-button type="primary" size="small" native-type="submit"
                @click.native.prevent="handleSearch">搜索</el-button>
            </el-col>
            <el-col class="search_col">
              <el-button size="small" @click="resetSearch">重置</el-button>
            </el-col>
            <el-col :span="24" class="btn_col">
              <el-upload :action="upUrl" multiple style="display: inline-block" :show-file-list="false"
                :headers="uploadHeaders" name="upfile" :accept="acceptFile" :on-change="handleUpChange"
                :before-upload="handleUpBefore" :on-exceed="handleUpExceed">
                <el-button size="small" type="primary">本地上传</el-button>
              </el-upload>
              <el-button type="primary" size="small" class="btn" @click="setResources">确定</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="res-box">
        <div class="res-list" v-infinite-scroll="loadMore" v-loading="loading">
          <div class="clearfix">
            <div class="res-item" v-for="(item, index) in listData" :key="index" @click="selectRes(index)">
              <div class="img-item">
                <img :src="resType == 1 ? item.web_path : '/images/video.png'" />
                <div class="res-size">
                  <div class="mask"></div>
                  <span>{{ item.size_name }}</span>
                </div>
                <div class="res-selected el-icon-success" v-if="item.isCheck"></div>
              </div>
              <div class="res-name" :title="item.name">{{ item.name }}</div>
            </div>
          </div>
          <div class="res-tag" v-if="!noData && noMore">没有更多了</div>
          <div class="res-tag" v-else-if="noData">暂无数据</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import resourceApi from "@/api/resource";
import requestApi from "@/lib/request";
export default {
  name: "ResourceWin",
  data() {
    return {
      visible: true,
      loading: true,
      sortLoading: false,
      btnLogining: false,
      upVisible: false,
      noMore: false,
      noData: false,
      searchForm: {
        key: "",
      },
      uploadHeaders: [],
      listData: [],
      limitNum: 10,
      upUrl: "/api/upload?action=uploadimage",
      acceptFile: "image/*",
      pageConfig: { pageSize: 20, pageIndex: 1, counts: 0 },
      fileNum: 0,
      fileList: [],
      upNum: {
        allNum: 0,
        sucNum: 0,
        errorNum: 0,
      },
    };
  },
  props: {
    resType: {
      type: Number,
      required: true,
    },
    isMore: {
      type: Boolean,
      required: true,
    },
  },
  _upLoadding: null,
  created() {
    if (this.resType == 2) {
      this.limitNum = 1;
      this.upUrl = "/api/upload?action=uploadvideo";
      this.acceptFile = "video/*";
    }
  },
  mounted() {
    let token = window.sessionStorage.getItem("token");
    let timestamp = requestApi.getTimeStamp();
    let sign = requestApi.createSign({}, timestamp);
    this.uploadHeaders = {
      Auth: token,
      timestamp: timestamp,
      sign: sign
    };
    this.getList();
  },
  methods: {
    //查询
    handleSearch() {
      this.getList();
    },
    resetSearch() {
      this.searchForm = {
        sort_id: 0,
        key: "",
      };
      this.getList();
    },
    setVisible(val) {
      this.visible = val;
    },
    //列表
    getList(pageIndex = 1) {
      this.loading = true;
      resourceApi
        .winList({
          key: this.searchForm.key,
          type: this.resType,
          page_size: this.pageConfig.pageSize,
          page_index: pageIndex,
        })
        .then((res) => {
          this.loading = false;
          res.list.map((item) => {
            item.isCheck = false;
            return item;
          });
          if (pageIndex == 1) {
            this.listData = res.list;
          } else {
            this.listData = this.listData.concat(res.list);
          }
          this.pageConfig.pageIndex = res.page.page_index;
          this.pageConfig.counts = res.page.counts;
          this.noData = res.page.counts == 0;
          if (
            res.page.counts > 0 &&
            this.pageConfig.pageIndex >= res.page.page_count
          )
            this.noMore = true;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    loadMore() {
      if (this.loading || this.noMore || this.noData) return;
      this.getList(this.pageConfig.pageIndex + 1);
    },
    //选择图片
    selectRes(index) {
      let listData = this.listData;
      if (!this.isMore && !listData[index].isCheck) {
        listData.map((item) => {
          item.isCheck = false;
          return item;
        });
      }
      listData[index].isCheck = !listData[index].isCheck;
      this.listData = listData;
    },
    //关闭
    resWinClose() {
      this.$emit("closeResourceWin", {});
    },
    handleUpBefore(file) {
      this._upLoadding = this.$loading({
        lock: true,
        customClass: "loading",
        text: "上传中",
      });
    },
    handleUpChange(file, fileList) {
      console.log(file.status);
      if (file.status == "ready") this.upNum.allNum++;
      if (file.status == "success") this.upNum.sucNum++;
      if (file.status == "fail") this.upNum.errorNum++;
      // console.log(fileList);
      // let errorNum = 0;
      // let successNum = 0;
      // fileList.forEach((item) => {
      //   if (item.status == "success") successNum++;
      //   if (item.status == "fail") errorNum++;
      //   return item;
      // });
      if (this.upNum.sucNum + this.upNum.errorNum == this.upNum.allNum) {
        this._upLoadding.close();
        this.$message({
          message:
            "上传成功" +
            this.upNum.sucNum +
            "个，失败" +
            this.upNum.errorNum +
            "个",
          type: this.upNum.errorNum == 0 ? "success" : "error",
          offset: 60,
        });
        if (this.upNum.sucNum > 0) this.getList();
        this.upNum = {
          allNum: 0,
          sucNum: 0,
          errorNum: 0,
        };
      }
    },
    handleUpExceed() {
      this.$message({
        message: "最多一次只能上传10个文件",
        type: "error",
        offset: 60,
      });
    },
    setResources() {
      let files = [];
      this.listData.forEach((item) => {
        if (item.isCheck) files.push(item);
        return item;
      });
      if (files.length <= 0) {
        this.$message({
          message: "请至少选择一个资源",
          type: "error",
          offset: 60,
        });
        return;
      }
      this.$emit("setResources", { files: files });
    },
    sortChange() {
      this.postData.sort_id = this.sortID;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileNum = fileList.length;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      if (file.status == "ready")
        this.fileNum = fileList.length;
      console.log(file.status);
      if (file.status == "success") this.upNum.sucNum++;
      if (file.status == "fail") this.upNum.errorNum++;
      if (this.upNum.sucNum + this.upNum.errorNum == this.fileNum) {
        this.btnLogining = false;
        // this._upLoadding.close();
        this.$message({
          message:
            "上传成功" +
            this.upNum.sucNum +
            "个，失败" +
            this.upNum.errorNum +
            "个",
          type: this.upNum.errorNum == 0 ? "success" : "error",
          offset: 60,
        });
        if (this.upNum.sucNum > 0) this.getList();
        this.upNum = {
          sucNum: 0,
          errorNum: 0,
        };
        this.upVisible = false;
      }
    },
    clearFile() {
      if (this.$refs.upload)
        this.$refs.upload.clearFiles();
    },
    upResources() {
      this.sortID = null;
      this.upVisible = true;
    },
    handleUpSubmit() {
      console.log(this.fileList);
      if (this.sortID == null) {
        this.$message({
          message: "请选择分类",
          type: "error",
          offset: 60,
        });
        return;
      }
      if (this.fileNum <= 0) {
        this.$message({
          message: "请选择图片",
          type: "error",
          offset: 60,
        });
        return;
      }
      this.btnLogining = true;
      this.$refs.upload.submit();
    }
  },
};
</script>
<style scoped>
.res-box {
  position: relative;
}

.res-box .res-list {
  height: 531px;
  text-align: center;
  overflow-y: auto;
  z-index: 1;
}

.res-box .res-item {
  width: 180px;
  height: 165px;
  float: left;
  margin: 8px 7px;
  cursor: pointer;
  text-align: center;
}

.res-box .res-item .img-item {
  height: 135px;
  background: #f5f5f5;
  position: relative;
  border: 1px solid #f5f5f5;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.res-box .res-item .img-item .res-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  text-align: center;
  height: 135px;
  width: 180px;
  overflow: hidden;
}

.res-box .res-item .img-item img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.res-box .res-item .img-item .res-size {
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  color: #fff;
  padding: 5px 0;
  line-height: 1.5;
  font-size: 12px;
}

.res-box .res-item .img-item .res-size span {
  position: relative;
  z-index: 1;
}

.res-box .res-item .img-item .res-size .mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00ce9b;
  opacity: 0.5;
}

.res-box .res-item .img-item .res-selected {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #00ce9b;
  line-height: normal;
  font-size: 20px;
}

.res-box .res-item .res-name {
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
}

.res-box .res-item:hover .img-item,
.res-box .res-item.selected .img-item {
  border: 1px solid #00ce9b;
}

.res-box .res-item.selected .img-item .res-selected {
  display: block;
}

.res-box .res-item:hover .img-item .res-size {
  bottom: 0;
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.res-box .res-item.selected .img-item .res-size {
  bottom: 0;
}

.res-box .res-tag {
  text-align: center;
  font-size: 12px;
  padding: 20px 0;
}
</style>
  