//表格方法
const setTableHeight = (page, isPagination = true) => {
    let otherHeight = isPagination ? 73 : 29;
    let top = page.$refs.pageBox.getBoundingClientRect().top;
    page.tableHeight = document.body.clientHeight - top - otherHeight;
}

module.exports = {
    onTableHeight(page, isPagination = true) {
        page.$nextTick(() => {
            setTableHeight(page, isPagination);
            window.onresize = () => {
                return (() => {
                    setTableHeight(page, isPagination);
                })()
            };
        });
    }
}